function Slide(){
    return(
      <div className='slide'>
        
        <div className='container'>
          <p>GENERA TODO TIPO DE COMPROBANTES</p>
          <p>DE LA MANERA MÁS RÁPIDA Y CÓMODA</p>
        </div>
      </div>
    )
  }
  export default Slide;