
function Header(){
    function NavHeader(){
        return(
          <div id="home" className="navHeader">
            <a href="https://www.youtube.com/@d2facfactureroelectronico683" target="_blank"><span><img src="./img/youtube.png" alt="Youtube d2fac" /></span></a>
            <a href="https://www.facebook.com/FacturacionElectronicaEnTusManos" target="_blank"><span><img src="./img/facebook.png" alt = "Facebook d2fac" /></span></a>
            <a href="https://www.instagram.com/d2fac/" target="_blank"><span><img src="./img/instagram.png" alt="Instagram d2fac" /></span></a>
          </div>
        )
    }

    function HeaderMenu() {
        return(
          <div className="App">
            <header>
              <div className='logo'>
                <img src="./img/logo_medium.png" alt="logo d2fac"/>
              </div>
              <nav className='menu'>
              <ul>
                <li>
                  <a href="/">Inicio</a>
                </li>
                <li>
                  <a href="#us">Quienes Somos</a>
                </li>
                <li>
                  <a href="#contact">Contacto</a>
                </li>
                <li className="menuButton">
                  <a href="https://vmredes.com/d2fac">Ingreso Clientes</a>
                </li>
              </ul>
              </nav>
              <div className='contact'>
                <div className='banner'>
                  <div className='contactText'>
                    <p className='title'>¡LLAMANOS!</p>
                    <p className='numbers'>0992543856 / 0983171596</p>
                  </div>
                </div>
              </div>
            </header>
          </div>
        )
    }

    return(
        <>
            <NavHeader />
            <HeaderMenu />
        </>
    )
}
export default Header