import Header from '../components/Header'
import Slide from '../components/Slide'
import AboutUs from '../components/AboutUs'
import Form from '../components/Form'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet';
function Inicio(){
    return(
        <>
            <Helmet>
            <title>D2FAC - Facturador electrónico</title>
            </Helmet>
            <Header />
            <Slide />
            <AboutUs />
            <Form />
            <Footer />
        </>
    )
}
export default Inicio