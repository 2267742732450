function Footer(){
    return(
      <>
        <footer>
          <div className="logoFooter">
            <img src="./img/logo_footer.png" alt="Footer d2fac" />
          </div>
          <div className="copyright">
            <p>Todos los derechos reservados. D2FAC 2023</p>
          </div>

        </footer>
      </>
    )
}
export default Footer