import Header from "../components/Header"
import Footer from "../components/Footer"

function Agradecimiento(){
    return (
        <>
        <Header />
        <div className="contactoA">
            <h5>GRACIAS POR TU MENSAJE, NOS CONTACTAREMOS CONTIGO LO MAS PRONTO POSIBLE</h5>
        </div>
        <Footer />
        </>
    )
}
export default Agradecimiento