
function AboutUs(){
    function Block1(){
        return(
          <>
            <div id="us" className="block1">
              <div className='title'>
                QUIENES SOMOS
              </div>
              <div className='desc'>
                Es una aplicación desarrollada en Ecuador orientado para cualquier tipo de persona o negocio con el propósito de facilitar el proceso de facturación, en la generación y almacenamiento de comprobates electrónicos y gestión de sus productos y servicios.
              </div> 
            </div>
          </>
        )
      }
      
      function Block2(){
        return(
          <>
            <div className="block2">
              <div className='devices'>
                <div className="titleBlock2">TODO SISTEMA OPERATIVO</div>
                <img src="./img/devices.png" alt="Promo d2fac" />
                <a className="btnAccess" href="https://vmredes.com/d2fac">INGRESO CLIENTES</a>
              </div>
              <div className='desc'>
                <div className="titleBlock2">CARACTERISTICAS</div>
                <ul>
                  <li>Generación de Facturas</li>
                  <li>Generación de Retenciones</li>
                  <li>Generación de Guias de Remisión</li>
                  <li>Generación de Liquidación de Compras</li>
                  <li>Generación de Notas de Crédito / Débito</li>
                  <li>Generación de Recibos / Proformas</li>
                  <li>Gestión de Productos / Servicios</li>
                  <li>Gestión de Clientes</li>
                  <li>Impresión de documentos PDF</li>
                  <li>Impresión en rollo (Ticket)</li>
                  <li>Reportes en formato EXCEL/PDF</li>
                  <li>Descarga de documentos PDF/XML</li>
                  <li>Respaldo de datos</li>
                  <li>Espacio para datos ilimitado</li>
                  <li>Soporte Técnico</li>
                </ul>
              </div> 
              <div className='promo'>
                <div className="titleBlock2">DESCARGA NUESTRA APP</div>
                <img src="./img/promo.png" alt="Promo d2fac" />
              </div> 
            </div>
          </>
        )
      }

      return(
        <>
            <Block1 />
            <Block2 />
        </>
      )
}
export default AboutUs