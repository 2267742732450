

import './App.css';
import './responsive.css';
import Agradecimiento from './pages/Agradecimiento'
import Inicio from './pages/Inicio'
import { Route, Routes, BrowserRouter } from "react-router-dom"

function App() {
  return (
    
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Inicio />} />
            <Route path="/inicio" element={<Inicio />} />
            <Route path="/gracias-por-contactarse" element={<Agradecimiento />} />   
          </Routes>
      </BrowserRouter>
  );
}



export default App;
